import {useEffect, useState} from 'react';
import underline from '../../../images/underline.svg';
import useInterval from '../quote-carousel/useInterval';

type  ICarouselProps = {
    data: string[];
    title?: string;
}

function Carousel({data=[], title}: ICarouselProps) {
    const [activeItem, setActiveItem] = useState(0);
    const [titleSplit, setTitleSplit] = useState(['']);
    const [sliding, setSliding] = useState(false);

    useInterval(() => {
        setActiveItem((activeItem + 1) % data.length);
            setSliding(true);
            setTimeout(() => {
                setSliding(false);
            }, 1000);
    }, 3000);

    useEffect(() => {
        if(title){
            setTitleSplit(title.split(' [...] '))
        }

    }, [title]);
    const offset = sliding ? 0 : 1; // To keep the same text after the slide
    return(
        <>
        <span className="carousel-wrapper">
            <span className="title">
                {titleSplit[0]}
                <span className="title carousel-text-wrapper">
                <span className="carousel-pre-text" data-sliding={sliding}>{data[(activeItem + 1 + offset) % data.length]}</span>
                <span className="carousel-text" data-sliding={sliding}>{data[(activeItem + offset) % data.length]}</span>
                {/* props.data.length is added to wrap around when index would be -1 */}
                <span className="carousel-post-text" data-sliding={sliding}>{data[((activeItem - 1 + offset) + data.length) % data.length]}</span>
                <img className="underline" src={underline} alt="Underline icon"/>
            </span>
            </span>
        </span>
        
        <div className="title">
            {titleSplit[1]}
        </div>
        </>
    )
}

export default Carousel;

