const FacebookIcon = () => {
    return (
        <svg width="9px" height="18px" viewBox="0 0 9 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
                d="M2.61477273,18 L2.61477273,9.94921875 L0,9.94921875 L0,6.75 L2.61477273,6.75 L2.61477273,4.22929688 C2.61477273,1.490625 4.2375,0 6.60681818,0 C7.74204545,0 8.71704545,0.087890625 9,0.1265625 L9,2.98828125 L7.35681818,2.98828125 C6.06818182,2.98828125 5.81931818,3.62109375 5.81931818,4.54570313 L5.81931818,6.75 L8.72727273,6.75 L8.32840909,9.94921875 L5.81931818,9.94921875 L5.81931818,18"
                id="Path" fill="currentColor"></path>
        </svg>
    );
}

export default FacebookIcon;
