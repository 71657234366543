const TelegramIcon = () => {
    return (
        <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
                d="M21.4066629,1.6422169 L18.1660914,16.9135095 C17.9272286,17.996354 17.2822992,18.2591031 16.3825828,17.7495292 L11.4460857,14.1108531 L9.06542014,16.4039356 C8.8026711,16.6666847 8.57973252,16.8896232 8.07015863,16.8896232 L8.42049068,11.8655431 L17.5689345,3.60089155 C17.9670391,3.2505595 17.4813515,3.0515072 16.9558534,3.40183925 L5.64968263,10.5199496 L0.776882277,8.99918999 C-0.28207597,8.66478212 -0.305962246,7.94023174 0.999820856,7.43065785 L20.037183,0.0896089422 C20.9209753,-0.228874741 21.6932982,0.304585429 21.4066629,1.6422169 L21.4066629,1.6422169 Z"
                id="Path" fill="currentColor"></path>
        </svg>
    );
}

export default TelegramIcon;
