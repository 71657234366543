import InfoImageBlock from "../../shared/info-image-block";
import './style.scss';
import TextBlock from "../../shared/text-block";
import TextBlockCenter from "../../shared/text-block-center";
import ReviewBlock from "../../shared/review-block";
import {IFAQResponse} from "../../../interfaces/strapiTypes/faq";
import BorderBlockFAQ from "../../shared/border-block/components/border-block-faq";
import HighlightBlock from "../../shared/highlight-block";
import useSWR from "swr";
import { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../App";
import BigTitleBlockWrapper from "../../shared/big-title-block-wrapper";

const WorkWayPage = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: faqBlock } = useSWR<IFAQResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/workway-page?populate[FAQ][populate]=faqs&locale=${locale}`)

    useEffect(() => {
        document.title = "RYSST - Werkwijze"
    }, [])

    return (
        <div className="workway-wrapper container">
            <TextBlock H1={true} blockurl="workway-page?populate[WelcomeBlock][populate][Info][populate]=Media" name="WelcomeBlock" partners={true}/>
            <InfoImageBlock blockurl={'workway-page?populate[WorkwayBlock][populate][Info][populate]=Media'}
                            name="WorkwayBlock"
                            reverse={true}
                            buttonType={"transparent"} />
            <div className="container" style={{padding: "50px 0"}}>
                <TextBlockCenter blockurl="workway-page?populate=CooperationBlock" name="CooperationBlock" />
                <BigTitleBlockWrapper  blockurl="workway-page?populate[Listblock][populate]=Image" />
            </div>
            <ReviewBlock blockurl="workway-page?populate[QuoteBlock][populate][Info][populate]=Media" quoteUrl="workway-page?populate[quotes][populate]=Photo"/>
            <HighlightBlock url="workway-page?populate[HighLightBlock][populate]=Media" blockName="HighLightBlock"/>
            <h2 className="title faq-title container">{faqBlock?.data.attributes.FAQ.Title}</h2>
            <div className="faq-wrapper">
                <div className="faq-column">
                    {faqBlock?.data.attributes.FAQ.faqs.data.map((faq, index) => {
                        if(index < faqBlock?.data.attributes.FAQ.faqs.data.length / 2){
                            return(
                                <BorderBlockFAQ faq={faq} key={index} />
                            )
                        }
                        return null;
                    })}
                </div>
                <div className="faq-column">
                    {faqBlock?.data.attributes.FAQ.faqs.data.map((faq, index) => {
                        if(index >= faqBlock?.data.attributes.FAQ.faqs.data.length / 2){
                            return(
                                <BorderBlockFAQ faq={faq} key={index} />
                            )
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
}

export default WorkWayPage;
