import './style.scss';
import React from "react";

type IColumnBlock = {
    title?: string,
    children: React.ReactNode
}

const ColumnBlock = ({children, title}: IColumnBlock) => {
    return (
        <div className="container column-wrapper">
            <div className='column-paragraph-wrapper'>
                {children}
            </div>
        </div>
    )
}

export default ColumnBlock;
