import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SWRConfig } from 'swr';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  const res = await fetch(input, {...init, headers: {
        'Accept-Encoding': 'br, gzip, deflate'
      }});
  return res.json();
};

root.render(
      <SWRConfig value={{ fetcher }}>
        <App />
      </SWRConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
console.log(`%c
██████╗ ██╗   ██╗███████╗███████╗████████╗███╗   ██╗██╗     
██╔══██╗╚██╗ ██╔╝██╔════╝██╔════╝╚══██╔══╝████╗  ██║██║     
██████╔╝ ╚████╔╝ ███████╗███████╗   ██║   ██╔██╗ ██║██║     
██╔══██╗  ╚██╔╝  ╚════██║╚════██║   ██║   ██║╚██╗██║██║     
██║  ██║   ██║   ███████║███████║   ██║██╗██║ ╚████║███████╗
╚═╝  ╚═╝   ╚═╝   ╚══════╝╚══════╝   ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝`, "color: #1772BD; font-size: 1.5rem");
console.log('%cBen je bekend met de console? Kom voor ons werken! https://rysst.nl/vacatures', "color: #1772BD; font-size: 1rem;");
reportWebVitals();
