import BorderBlock from '../border-block';
import './style.scss';
import BorderBlockTeam from '../border-block/components/border-block-team';
import TeamCarousel from '../team-carousel';
import {ITeam} from '../../../interfaces/strapiTypes/team';

type ITeamProps = {
    reverse?: boolean,
    team: ITeam,
    first: boolean
}

const Team = ({reverse, team, first}:ITeamProps) => {
    return (
        <div className={`container team-wrapper ${reverse ? 'team-wrapper-reversed' : ''} ${first ? 'team-wrapper-first' : ''}`}>
            <div className="team-description-wrapper">
                <BorderBlock>
                    <BorderBlockTeam team={team}/>
                </BorderBlock>
            </div>
            <TeamCarousel reverse={reverse} team={team.attributes.team_members.data} type={"small"}/>
        </div>
    );

}

export default Team;
