import './style.scss';
import {useContext, useEffect, useState} from 'react';
import BlogList from './blog-list';
import useSWR from 'swr';
import {ITagResponse} from '../../../interfaces/strapiTypes/tag';
import { LocalizationContext } from '../../../App';

const BlogPage = () => {
    const { locale } = useContext(LocalizationContext)
    const {data: tagsList} = useSWR<ITagResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/tags?locale=${locale}`);
    const [checked, setChecked] = useState<string[]>([]);

    useEffect(() => {
        document.title = "RYSST - Blogs";
        setChecked([]);

    }, [tagsList?.data]);

    const handleChecked = (e: React.ChangeEvent) => {
        const cast = e.target as HTMLInputElement;
        if (cast.checked)
            setChecked([...checked, cast.value]);
        else
            setChecked(checked.filter(value => value !== cast.value));
    }

    return (
        <div className="blog-page container">
            <h1 className="page-title">Blogs</h1>
            <div className="blog-tags-wrapper">
                {tagsList?.data?.map(tag => {
                    return (
                        <label key={tag.id}>
                            <input type="checkbox" name="test" className="blog-tag" value={tag.attributes.Name} onChange={handleChecked}/>
                            <span className="tag-checkbox"></span>
                        #{tag.attributes.Name}
                        </label>
                    )
                })}
            </div>
            <BlogList blockurl={'blog-posts?populate=tag&populate=Thumbnail&fields[0]=Title&fields[1]=WideThumbnail&fields[2]=Slug'} selectedTags={checked} />
        </div>
    );
};

export default BlogPage;
