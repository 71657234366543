import {Link} from "react-router-dom";
import React, {MouseEventHandler, useEffect} from "react";
import { useLocation } from 'react-router-dom'

interface IMenuItemsProps{
    callBack: MouseEventHandler
}

const MenuItems = ({callBack}: IMenuItemsProps) => {
    const location = useLocation();

    useEffect(() => {
    }, [location])


    return (
        <nav>
            <ul>
                <li className={location.pathname === "/" ? "selected" : ""}>
                    <Link to="/" onClick={callBack}>Home</Link>
                </li>
                <li className={location.pathname === "/overrysst" ? "selected" : ""}>
                    <Link to="/overrysst" onClick={callBack}>Over RYSST</Link>
                </li>
                <li className={location.pathname === "/teams" ? "selected" : ""}>
                    <Link to="/teams" onClick={callBack}>Onze teams</Link>
                </li>
                <li className={location.pathname === "/werkwijze" ? "selected" : ""}>
                    <Link to="/werkwijze" onClick={callBack}>Werkwijze</Link>
                </li>
                <li className={location.pathname === "/blog" ? "selected" : ""}>
                    <Link to="/blog" onClick={callBack}>Blog</Link>
                </li>
            </ul>
        </nav>
    );
}

export default MenuItems;
