import Button from '../../button';
import './style.scss'
import {Url} from "../../../../interfaces/strapiTypes/url";
import {IColumnResponse} from "../../../../interfaces/strapiTypes/column";
import parse from 'html-react-parser';
import useSWR from 'swr';
import { useContext } from 'react';
import { LocalizationContext } from '../../../../App';

type IColumnInfoProps = Url & {
    buttonType?: 'primary' | 'secondary' | 'transparent'
}

const ColumnInfo = ({blockurl, name = '', buttonType = 'primary'}: IColumnInfoProps) => {
    const { locale } = useContext(LocalizationContext)
    const { data: columnContent } = useSWR<IColumnResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <div className="column-one column">
            <h3 className="column-title">
                {columnContent?.data.attributes[name]?.ButtonText || columnContent?.data.attributes[name]?.Images ?
                columnContent?.data.attributes[name]?.ColumnTitle?.Title :
                columnContent?.data.attributes[name]?.Title}
            </h3>
            {parse((columnContent?.data.attributes[name]?.ButtonText || columnContent?.data.attributes[name]?.Images ?
                columnContent?.data.attributes[name]?.ColumnTitle?.ColumnInfo :
                columnContent?.data.attributes[name]?.ColumnInfo) || '')}

            {columnContent?.data.attributes[name]?.ButtonText &&
            <Button link={columnContent?.data.attributes[name]?.ButtonLink} type={buttonType ? buttonType : 'primary'}>
                {columnContent?.data.attributes[name]?.ButtonText}
            </Button>}
            {
                <div className="column-info-images-wrapper">
                    {columnContent?.data.attributes[name]?.Images?.data.map((image: any, index: number) => {
                        return (<img src={(process.env.REACT_APP_BACKEND_URL || '') + image.attributes.url} alt="" key={index}/>)
                    })}
                </div>
            }
        </div>
    )
}

export default ColumnInfo;
