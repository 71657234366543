import {useContext, useEffect, useState} from "react";
import {LocalizationContext} from "../../../App";
import './style.scss';
import Button from "../../shared/button";
import useSWR from "swr";
import {IApiResponse} from "../../../interfaces/strapiTypes/apiResponse";

const Whitepaper = () => {
    const {locale} = useContext(LocalizationContext)
    const [buttonText, setButtonText] = useState('')
    const {data: whitepaperData} = useSWR<IApiResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/whitepaper?populate=*`)

    useEffect(() => {
        if (window.innerWidth <= 850) {
            setButtonText(whitepaperData?.data.attributes.buttontextphone)
        } else {
            setButtonText(whitepaperData?.data.attributes.buttontextdesktop)
        }
    }, [whitepaperData])


    return (
        <div className="whitepaper-container">
            <div className="image-block">
                <img
                    src={(process.env.REACT_APP_BACKEND_URL || "") + whitepaperData?.data.attributes.whitepaperimage.data.attributes.url}
                    alt="whitepaper"/>
            </div>
            <div className="text-block">
                <h1>{whitepaperData?.data.attributes.title}</h1>
                <p>{whitepaperData?.data.attributes.subtext}</p>
                <Button type="primary">
                    <ul>
                        <li>
                            <a href={(process.env.REACT_APP_BACKEND_URL || "") + whitepaperData?.data.attributes.whitepaper.data.attributes.url} target="_blank" rel="noreferrer">{buttonText}</a>
                        </li>
                    </ul>
                </Button>

            </div>
        </div>
    )
}

export default Whitepaper;