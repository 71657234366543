import './style.scss';
import Button from "../button";
import {IInfoBlockContentResponse} from "../../../interfaces/strapiTypes/infoBlock";
import parse from 'html-react-parser';
import {Url} from "../../../interfaces/strapiTypes/url";
import Companies from "../companies";
import useSWR from 'swr';
import { useContext } from 'react';
import { LocalizationContext } from '../../../App';

type ITextBlockProps = Url & {
    partners?: boolean,
    buttonType?: 'primary'|'secondary'|'transparent' | 'transparent-gray',
    H1?: boolean
}


const TextBlock = ({blockurl, buttonType='primary', name = '', partners, H1 = false}: ITextBlockProps) => {
    const { locale } = useContext(LocalizationContext)
    const { data: textBlockContent } = useSWR<IInfoBlockContentResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <section className="container text-block-wrapper">
            <div className="text-block-info-wrapper">
                <div className="info">
                    <div className="info-title-wrapper">
                        {H1 ? <h1 className="text-block-info-title title">{textBlockContent?.data.attributes[name].Info?.Title}</h1>
                        : <h2 className="text-block-info-title title">{textBlockContent?.data.attributes[name].Info?.Title}</h2>}
                        <p className="text-block-info-sub-title sub-title">{textBlockContent?.data.attributes[name].Info?.Subtitle}</p>
                    </div>
                    <div className="info-paragraph-wrapper">
                        <span className="text-block-info-paragraph-title sub-title  ">{ textBlockContent?.data.attributes[name].Paragraphtitle }</span>
                        {parse(textBlockContent?.data.attributes[name].Content || '')}
                    </div>
                    {
                        textBlockContent?.data.attributes[name].Info?.ButtonText ?
                        <div className='info-button-wrapper'>
                            <Button link={textBlockContent?.data.attributes[name].Info.ButtonLink} type={buttonType}>
                                {textBlockContent?.data.attributes[name].Info?.ButtonText}
                            </Button>
                        </div> : []
                    }
                </div>
                {partners ? <Companies /> : []}
            </div>
            <div className="image-wrapper">
                {textBlockContent?.data.attributes[name].Info?.Media ? 
                <img src={(process.env.REACT_APP_BACKEND_URL || "") + textBlockContent?.data.attributes[name].Info?.Media?.data.attributes.url} alt="info"/> 
                : null }
            </div>

        </section>
    );
}

export default TextBlock;
