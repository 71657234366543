import React, {useState} from "react";
import './style.scss';
import { IFAQ } from "../../../../../interfaces/strapiTypes/faq";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

type IBorderBlockFAQProps = {
    faq: IFAQ,
}

const BorderBlockFAQ = ({faq}: IBorderBlockFAQProps ) => {
    const [open, setOpen] = useState<boolean>(false)
    const openFaq = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    return (
        <div className='container border-block'>
            <div className={`border-block-wrapper border-block-faq-wrapper ${ !open ? 'border-block-faq-wrapper-closed' : ''}`} onClick={openFaq}>
                <div className="border-block-question-wrapper">
                    <p>{faq.attributes.Question}</p>
                    <ArrowUpwardIcon style={{
                        transform: open ? 'rotate(180deg)' : 'rotate(0)',
                        color: open ? '#00BDF1' : 'var(--text-color)'}}/>
                </div>
                <div className="border-block-answer-wrapper">
                    <p className="border-block-answer">{faq.attributes.Answer}</p>
                </div>
            </div>
        </div>
        
    );
}

export default BorderBlockFAQ;
