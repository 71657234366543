import {Link} from 'react-router-dom';
import BorderBlock from '../../../shared/border-block';


type IBlogProps = {
    reverse: boolean,
    wideThumbnail: boolean,
    title: string,
    imgUrl: string,
    slug: string,
    tag: string
}

const BlogPostTeaser = ({reverse, wideThumbnail, title, imgUrl, slug, tag}: IBlogProps) => {
    return (
        <Link to={`/blog/${slug}`}>
            <div className={reverse ? 'blog-post-teaser-reverse' : 'blog-post-teaser'}>
                <img className={wideThumbnail ? 'blog-post-teaser-thumbnail-wide' : 'blog-post-teaser-thumbnail'}
                     src={(process.env.REACT_APP_BACKEND_URL || '') + imgUrl} alt="Blog post teaser thumbnail"/>
                <BorderBlock>
                    <span className="blog-post-teaser-tag">{tag ? `#${tag}` : ''}</span>
                    <h2 className="blog-post-teaser-title">{title}</h2>
                </BorderBlock>
            </div>
        </Link>
    )
}

export default BlogPostTeaser;
