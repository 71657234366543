import './style.scss';
import {Navigate, useParams} from 'react-router-dom';
import useSWR from 'swr';
import {IVacancyResponse} from '../../../../interfaces/strapiTypes/vacancy';
import BorderBlock from '../../../shared/border-block';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RepeatIcon from '@mui/icons-material/Repeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';
import Button from '../../../shared/button';
import {useContext, useEffect, useState} from 'react';
import {LocalizationContext} from '../../../../App';
import {IInfoBlockResponse} from '../../../../interfaces/strapiTypes/infoBlock';
import Form from '../../../shared/form';

const Vacancy = () => {
    const { slug } = useParams();
    const { locale } = useContext(LocalizationContext);
    const { data: vacancy } = useSWR<IVacancyResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/vacancies?populate=image&filters[slug][$eq]=${slug}&locale=${locale}`);
    const { data: vacancyLocalization } = useSWR<IVacancyResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/vacancies?populate=image&filters[localizations][slug][$eq]=${slug}&locale=${locale}`);
    const { data: vacancyProcess } = useSWR<IInfoBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/vacancy-page?populate=Process&locale=${locale}`)

    const [openForm, setOpenForm] = useState(false);
    const [closeButton, setCloseButton] = useState(false);

    const formSubmitHandler = (event: any) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
          if (event.data.id === '48bf92f7-af2b-4407-b8dc-4c05857ff2da') {
            setCloseButton(true);
          }
        }
    }

    useEffect(() => {
      document.title = "RYSST - Vacature | " + vacancy?.data[0]?.attributes.title;
      window.addEventListener('message', formSubmitHandler);
      return () => {
          window.removeEventListener('message', formSubmitHandler);
      }
      // eslint-disable-next-line
    }, [vacancy?.data]);

    if (vacancy?.data?.length === 0 && vacancyLocalization !== undefined) {
        const redirectTarget = vacancyLocalization.data.length === 0 || !vacancyLocalization.data[0].attributes.available ? '' : `/${vacancyLocalization?.data[0].attributes.slug}`;
        return (
            <Navigate to={`/vacatures${redirectTarget}`} replace={true} />
        )
    }

    return (
        <div className="vacancy-wrapper wrapper">
          <div className="container vacancy-title-container">
            <h1 className="title vacancy-title">Vacature {vacancy?.data[0]?.attributes.title}</h1>
            <p className="sub-title">{vacancy?.data[0]?.attributes.tag}</p>
            <BorderBlock>
              <div className="vacancy-header-wrapper">
                  <img src={(process.env.REACT_APP_BACKEND_URL || '') + vacancy?.data[0]?.attributes.image.data.attributes.url} alt="vacancy" />
                  <div className="vacancy-header-info-wrapper">
                    <div className="vacancy-header-info">
                      <div className="vacancy-info-item">
                        <AccessTimeIcon />
                        <p className="vacancy-info-item-title">Aantal uren (per week)</p>
                        <p>{vacancy?.data[0]?.attributes.hours} uur</p>
                      </div>
                      <div className="vacancy-info-item">
                        <RepeatIcon />
                        <p className="vacancy-info-item-title">Vakgebied</p>
                        <p>{vacancy?.data[0]?.attributes.discipline}</p>
                      </div>
                      <div className="vacancy-info-item">
                        <LocationOnIcon />
                        <p className="vacancy-info-item-title">Locatie</p>
                        <p>{vacancy?.data[0]?.attributes.location.replace('_', ' & ')}</p>
                      </div>
                    </div>
                    <div className="vacancy-header-info">
                      <div className="vacancy-info-item">
                        <CalendarMonthIcon />
                        <p className="vacancy-info-item-title">Starten</p>
                        <p>{vacancy?.data[0]?.attributes.startDate === '-' ? 'Doorlopend' : vacancy?.data[0]?.attributes.startDate}</p>
                      </div>
                      <div className="vacancy-info-item">
                        <DirectionsCarIcon />
                        <p className="vacancy-info-item-title">Reiskostenvergoeding</p>
                        <p>€{String(vacancy?.data[0]?.attributes.travelAllowance)}/km</p>
                      </div>
                      <div className="vacancy-info-item">
                        <EventBusyIcon />
                        <p className="vacancy-info-item-title">Einddatum solliciteren</p>
                        <p>{vacancy?.data[0]?.attributes.applicationEndDate === '-' ? 'Doorlopend' : vacancy?.data[0]?.attributes.applicationEndDate}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </BorderBlock>
          </div>
          <div className="vacancy-description-container">
           <div className="vacancy-process-wrapper">
              <h2 className="vacancy-process-title">{vacancyProcess?.data.attributes.Process.Title}</h2>
              <div className="vacancy-process">
                {parse(vacancyProcess?.data.attributes.Process.Description||'')}
              </div>
              <Button clickHandler={() => {setOpenForm(true)}}>Solliciteer</Button>
              {openForm && <div className="vacancy-form">
              {!closeButton && <span className="vacancy-form-header">
                <p className="vacancy-process-title">Sollicitatieformulier</p>
                <CloseIcon onClick={() => {setOpenForm(false)}}/>
              </span>}
                <Form formId="48bf92f7-af2b-4407-b8dc-4c05857ff2da" />
                {closeButton && <span className="vacancy-form-button">
                  <Button clickHandler={() => {setOpenForm(false); setCloseButton(false)}} type='transparent-gray'>Sluit formulier</Button>
                </span>}
              </div>}
            </div>
            <div className="vacancy-description">{parse(vacancy?.data[0]?.attributes.description||'')}</div>
          </div>
        </div>
    );
}

export default Vacancy;
