import './style.scss';
import {Link} from 'react-router-dom';
import {IFooterResponse} from '../../interfaces/strapiTypes/footer';
import useSWR from 'swr';
import {useContext} from 'react';
import {LocalizationContext} from '../../App';
import {IHeaderFooter} from '../../interfaces/IHeaderFooter';
import RysstLogoWhite from '../../images/1-RYSST_logos/RYSST_White.webp';
import RysstLogoBlack from '../../images/1-RYSST_logos/RYSST_Black.webp';

import LinkedinIcon from "../icons/linkedin";
import FacebookIcon from "../icons/facebook";
import TelegramIcon from "../icons/telegram";

const Footer = ({theme}: IHeaderFooter) => {
    const { locale } = useContext(LocalizationContext)
    const { data: footerContent } = useSWR<IFooterResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/footer?populate=deep&locale=${locale}`)

    return (
        <footer>
            <div className="footer-wrapper">
                <div className=" container footer-menu-wrapper">
                    <div className="footer-end-credits">
                        <div className="footer-end-credits-img">
                            <img src={!theme ? RysstLogoWhite : RysstLogoBlack} alt="Rysst logo"/>
                        </div>
                        <div className="footer-end-credits-content">
                            <p>
                                {footerContent?.data.attributes.FooterContent.Description}
                            </p>
                        </div>
                    </div>
                    <div className="footer-menu-items-wrapper">
                        <div className="footer-menu-generic">
                            <p>Algemeen</p>
                            <div className="footer-menu-generic-items">
                                <ul>
                                    <li>
                                        <Link to="/overrysst">Over RYSST</Link>
                                    </li>
                                    <li>
                                        <Link to="/teams">Onze teams</Link>
                                    </li>
                                    <li>
                                        <Link to="/werkwijze">Werkwijze</Link>
                                    </li>
                                    <li>
                                        <a href={(process.env.REACT_APP_BACKEND_URL || "") + footerContent?.data.attributes.AlgemeneVoorwaarden.data.attributes.url} target="_blank" rel="noreferrer">Algemene voorwaarden</a>
                                    </li>
                                    <li>
                                        <a href={(process.env.REACT_APP_BACKEND_URL || "") + footerContent?.data.attributes.PrivacyStatement.data.attributes.url} target="_blank" rel="noreferrer">Privacy statement</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-menu-work-at">
                            <p>Werken bij</p>
                            <div className="footer-menu-work-at-items">
                                <ul>
                                    <li>
                                        <Link to="/voor-studenten">Voor studenten</Link>
                                    </li>
                                    <li>
                                        <Link to="/vacatures">Vacatures</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-menu-contact">
                        <p className="footer-menu-contact-title">Neem contact op met RYSST</p>
                        <div className="footer-menu-contact-adresses">
                            { footerContent?.data.attributes.locations?.data.map((location, index) => (
                                <div key={index} className="footer-menu-contact-adress">
                                    <a href={location.attributes.GoogleMapsLink} target="_blank" rel="noreferrer" className="title">{location.attributes.City}</a>
                                    <p className="grey-text">{location.attributes.Address}</p>
                                </div>
                            ))}
                        </div>
                        <div className="footer-menu-contact-info">
                            <p className="grey-text">E-mail: <a className="grey-text" href={"mailto:" + footerContent?.data.attributes.FooterContent.Email}><span>{footerContent?.data.attributes.FooterContent.Email}</span></a></p>
                            <p className="grey-text">Telefoon: <a className="grey-text" href={"tel: " + footerContent?.data.attributes.FooterContent.Phonenumber}><span>{footerContent?.data.attributes.FooterContent.Phonenumber}</span></a></p>
                        </div>
                        <div className="footer-menu-socials">
                            <a href="https://www.linkedin.com/company/10212397/" target="_blank" rel="noreferrer"><LinkedinIcon /> </a>
                            <a href="https://www.facebook.com/rentyourstudentscrumteam/" target="_blank" rel="noreferrer"><FacebookIcon /></a>
                            <a href="tg://resolve?domain=rysstnl" target="_blank" rel="noreferrer" ><TelegramIcon    /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright-wrapper">
                <div className="container">
                    <p className="copyright">Copyright &copy; {footerContent?.data.attributes.FooterContent.CopyrightYear} RYSST</p>
                </div>
            </div>

        </footer>
    );
}

export default Footer;
