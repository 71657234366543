import { ITeam } from "../../../../../interfaces/strapiTypes/team";
import './style.scss'

type ITeamProps = {
    team: ITeam
}

const BorderBlockTeam = ({team}: ITeamProps) => {
    return (
        <div className='border-block-team-wrapper'>
            <div className='border-block-title'>
                <span className="border-block-sub-title">{team.attributes.TeamName}</span>
            </div>
            <div>
                <strong><p>{team.attributes.TeamDescription}</p></strong>
            </div>
            <div className='border-block-text'>
                <p>{team.attributes.Description}</p>
            </div>
        </div>
    );
}

export default BorderBlockTeam;
