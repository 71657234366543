import React, {useContext, useEffect, useState} from 'react';
import './style.scss';
import {ITeamMember, ITeamMemberResponse} from '../../../interfaces/strapiTypes/teamMember';
import useSWR from 'swr';
import {LocalizationContext} from '../../../App';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import MemberImage from "../member-image";

type IQuoteCarouselProps = {
    team?: ITeamMember[],
    blockurl?: string,
    reverse?: boolean,
    type?: 'wide' | 'small'
}

const IMG_MARGIN = 20

const TeamCarousel = ({team, reverse, type, blockurl}: IQuoteCarouselProps) => {
    const {locale} = useContext(LocalizationContext)
    const {data: teamData} = useSWR<ITeamMemberResponse>(blockurl ? `${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}` : null, {
        onSuccess:
            (data, key, config) => {
                setTeamMembers(data.data.attributes.Employees.team_members.data)
            }
    })

    const [teamMembers, setTeamMembers] = useState<ITeamMember[]>(teamData?.data.attributes.Employees.team_members.data || [])
    const [imageWidth, setImageWidth] = useState(window.innerWidth <= 850 ? 125 : 300)
    const [amountPerSlide, setAmountPerSlide] = useState(8)
    const [slidePosition, setSlidePosition] = useState<number>(0)

    const isMobile = window.innerWidth <= 850;

    const getAmountPerSlide = () => {
        const width = window.innerWidth
        if (type !== "wide") {
            setAmountPerSlide(4)
            return
        }

        if (width >= 1500) {
            setAmountPerSlide(8)
        } else if (width > 1080 && width < 1500) {
            setAmountPerSlide(6)
        } else {
            setAmountPerSlide(4)
        }
    }

    useEffect(() => {
        if (team && teamMembers.length === 0) {
            setTeamMembers(team)
        }

        const handleWindowSize = () => {
            isMobile ? setImageWidth(125) : setImageWidth(300);
            getAmountPerSlide()
        }

        window.addEventListener('resize', handleWindowSize);
        getAmountPerSlide()

        return () => {
            window.removeEventListener('resize', handleWindowSize);
        }
        // it wants the getAmountPerSlide in the dependencies too
        // eslint-disable-next-line
    }, [imageWidth, isMobile, team, teamMembers.length, reverse]);

    const changeSlidePosition = () => {
        let position = 0
        if (type === "wide" && !isMobile) {
            position = slidePosition - ((imageWidth + IMG_MARGIN) * 2) <=  (Math.ceil(teamMembers.length / 2) - 2) * -(imageWidth + IMG_MARGIN) ? 0 : slidePosition -((imageWidth + IMG_MARGIN) * 2)
        } else {
            position = slidePosition - (imageWidth + IMG_MARGIN) === (Math.ceil(teamMembers?.length / Math.floor(amountPerSlide / 2)) - 1) * -(imageWidth + IMG_MARGIN) ? 0 : slidePosition - imageWidth - IMG_MARGIN
        }

        setSlidePosition(position)
    }
    
    const checkArrowDirection = () => {
        if (type === 'wide'  && !isMobile) {
            return !(slidePosition - ((imageWidth + IMG_MARGIN) * 2) <=  (Math.ceil(teamMembers.length / 2) - 2) * -(imageWidth + IMG_MARGIN))
        }

        let condition = slidePosition - (imageWidth + IMG_MARGIN) === (Math.ceil(teamMembers?.length / Math.floor(amountPerSlide / 2)) - 1) * -(imageWidth + IMG_MARGIN)
        if (reverse) {
            return condition;
        }

        return !condition;
    }

    return (
        <div
            className={`team-carousel ${teamMembers.length > 4 ? 'team-carousel-shadow' : ''} ${reverse && !isMobile ? 'team-carousel-reversed' : ''} ${type === "wide" ? 'team-carousel-wide container' : ''}`}
        >
            <div
                className={`team-carousel-wrapper ${isMobile && teamMembers.length <= 2 ? 'team-carousel-wrapper-no-button' : ''}`}>
                <div className="team-carousel-items" style={{
                    left: reverse && !isMobile ? 'unset' : slidePosition,
                    maxWidth: (Math.ceil(teamMembers.length / 2)) * (imageWidth + IMG_MARGIN),
                    minWidth: (Math.ceil(teamMembers.length / 2)) * (imageWidth + IMG_MARGIN),
                    width: (Math.ceil(teamMembers.length / 2)) * (imageWidth + IMG_MARGIN),
                    right: reverse && !isMobile ? slidePosition : 'unset',
                    position: teamMembers.length <= 8 && type === 'wide' ? 'relative' : 'absolute'
                }}>
                    {
                        teamMembers.map((member, index) =>
                            <div
                                key={index}
                                className={`slide carousel-slide-${index} ${index % 2 === 0 && index < teamMembers.length - 2 && teamMembers.length > 2 ? 'slide-circle' : ''}`}>
                                <MemberImage member={member}></MemberImage>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                (teamMembers.length > amountPerSlide)
                    ?
                    <div className={`quote-carousel-navigation ${reverse ? 'left' : 'right'} `} onClick={changeSlidePosition}>
                        {checkArrowDirection() ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                    </div>
                    :
                    null
            }

        </div>
    );
}

export default TeamCarousel;
