import {useContext, useEffect} from 'react';
import './style.scss';
import useSWR from 'swr';
import BorderBlock from '../../shared/border-block';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {LocalizationContext} from '../../../App';
import Form from '../../shared/form';
import { IInfoBlockContactkResponse } from '../../../interfaces/strapiTypes/infoBlock';
import { IFooterResponse } from '../../../interfaces/strapiTypes/footer';
import parse from 'html-react-parser';
import HighlightBlock from '../../shared/highlight-block';

const Contact = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: contactContent } = useSWR<IInfoBlockContactkResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/contact-page?populate=deep&locale=${locale}`)
    const { data: footerContent } = useSWR<IFooterResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/footer?populate=deep&locale=${locale}`)

    useEffect(() => {
        document.title = "RYSST - Contact"
    }, [])

    return (
        <>
            <div className="contact-wrapper wrapper">
                <div className="contact-location-wrapper">
                    <h1>{ contactContent?.data.attributes.Info.Title }</h1>
                    <p className='sub-title'>{ contactContent?.data.attributes.Info.Subtitle }</p>
                    <div>{ parse(contactContent?.data.attributes.Info.Description || "") }</div>
                    <div className="contact-locations">
                        { footerContent?.data.attributes.locations?.data.map((location, index) => (
                            <div key={index} className="contact-location">
                                <BorderBlock>
                                    <div>
                                        <img src={(process.env.REACT_APP_BACKEND_URL || '') + location.attributes.Photo.data.attributes.url} alt={location.attributes.Address} />
                                    </div>
                                    <div className="contact-details-wrapper">
                                        <p className="border-block-title city">{location.attributes.City}</p>
                                        <div className="contact-details">
                                            <p><LocationOnIcon /><a className="address" href={location.attributes.GoogleMapsLink} target="_blank" rel="noreferrer">{location.attributes.Address}</a></p>
                                            <p><EmailIcon /><a href={"mailto:" + footerContent?.data.attributes.FooterContent.Email}><span>{footerContent?.data.attributes.FooterContent.Email}</span></a></p>
                                            <p><PhoneIcon /><a href={"tel: " + footerContent?.data.attributes.FooterContent.Phonenumber}><span>{footerContent?.data.attributes.FooterContent.Phonenumber}</span></a></p>
                                        </div>
                                    </div>
                                </BorderBlock>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="contact-form-container">
                    <Form formId="f439f48e-52cb-4984-9b5a-0fe9d35d17ac" />
                </div>
            </div>
            <HighlightBlock url="about-page?populate=HighLightBlock" blockName="HighLightBlock" />
        </>
    );
}

export default Contact;
