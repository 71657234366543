import './style.scss';
import useSWR from "swr";
import { IPartnersResponse } from "../../../interfaces/strapiTypes/partners";
import { LocalizationContext } from '../../../App';
import { useContext } from 'react';

const Companies = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: partnersContent } = useSWR<IPartnersResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/home-page?populate[PartnerBlock][populate]=PartnerImages&locale=${locale}`)

    return (
        <div className="companies-wrapper">
            <div className="companies">
                <div className="companies-info-wrapper">
                    <p>{partnersContent?.data.attributes.PartnerBlock.PartnerText}</p>
                    <div className="logos">
                        {partnersContent?.data.attributes.PartnerBlock.PartnerImages?.data?.map(image => (
                            <img key={image.id}
                                 src={(process.env.REACT_APP_BACKEND_URL || "") + image.attributes.url}
                                 alt={image.attributes.alternativeText}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Companies;
