const LinkedinIcon = () => {
    return (
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
                d="M4.02901138,18 L0.297255077,18 L0.297255077,5.98258929 L4.02901138,5.98258929 L4.02901138,18 Z M2.16112475,4.34330357 C0.96808748,4.34330357 0,3.35491071 0,2.16160714 C0,0.968303571 0.96808748,0 2.16112475,0 C3.35416202,0 4.3222495,0.968303571 4.3222495,2.16160714 C4.3222495,3.35491071 3.35416202,4.34330357 2.16112475,4.34330357 Z M17.995983,18 L14.2722607,18 L14.2722607,12.15 C14.2722607,10.7558036 14.2441419,8.96785714 12.3320687,8.96785714 C10.3918768,8.96785714 10.0946217,10.4825893 10.0946217,12.0495536 L10.0946217,18 L6.36688239,18 L6.36688239,5.98258929 L9.9459942,5.98258929 L9.9459942,7.621875 L9.99821468,7.621875 C10.4963178,6.67767857 11.7134568,5.68125 13.529123,5.68125 C17.3050658,5.68125 18,8.16830357 18,11.3986607 L18,18 L17.995983,18 Z"
                id="Shape" fill="currentColor"></path>
        </svg>
    );
}

export default LinkedinIcon;
