import Team from "../../shared/team";
import './style.scss';
import TextBlock from "../../shared/text-block";
import HighlightBlock from "../../shared/highlight-block";
import { ITeamResponse } from "../../../interfaces/strapiTypes/team";
import Divider from "../../shared/divider";
import ColumnBlock from "../../shared/column-block";
import ColumnInfo from "../../shared/column-block/column-info";
import useSWR from "swr";
import { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../App";

const OurTeams = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: teams } = useSWR<ITeamResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/team-page?populate[teams][populate][team_members][populate]=Photo&locale=${locale}`)

    useEffect(() => {
        document.title = "RYSST - Onze teams"
    }, [])

    return (
        <div className='teams-wrapper'>
            <TextBlock H1={true} blockurl='team-page?populate[HeaderImage][populate][Info][populate]=Media' name='HeaderImage' />
            <div className="column-background-wrapper dark-background">
                <h2 className="title">Onze teams</h2>
                <ColumnBlock>
                    <ColumnInfo blockurl="team-page?populate[Column1][populate][ColumnTitle][populate]=deep" buttonType="secondary" name="Column1" />
                    <ColumnInfo blockurl="team-page?populate=Column2" buttonType="secondary" name="Column2" />
                    <ColumnInfo blockurl="team-page?populate[Column3][populate]=*" name="Column3" />
                </ColumnBlock>
            </div>

            <h2 className="title container">Onze teams</h2>
            {teams?.data.attributes.teams.data.map((team, index) => {
                return(
                    <div key={index}>
                        <Team first={index === 0} key={index} team={team} reverse={index % 2 === 0} />
                        {index < teams.data.attributes.teams.data.length - 1 && <Divider type="horizontal" className="team-divider" color="gray" />}
                    </div>
                )}
            )}
            <HighlightBlock url="team-page?populate[HighlightBlock][populate]=Media" />
        </div>
    );
}

export default OurTeams;
