import React from 'react';
import './style.scss';
import {IVacancy} from '../../../../../interfaces/strapiTypes/vacancy';
import Button from '../../../button';

type IBorderBlockQuoteProps = {
    vacancy?: IVacancy,
    blockurl?: string,
}

const BorderBlockVacancy = ({vacancy}: IBorderBlockQuoteProps ) => {
    return (
        <div className="vacancy">
            <div className="vacancy-content-wrapper">
                <img src={(process.env.REACT_APP_BACKEND_URL || '') + vacancy?.attributes.image.data.attributes.url} alt="vacancy" />
                <div className="vacancy-content">
                    <span className="vacancy-title border-block-title">{vacancy?.attributes.title}</span>
                    <p className="vacancy-subtitle border-block-sub-title">{vacancy?.attributes.tag.replace('_', '/') + ', ' + vacancy?.attributes.location.replace('_', ' & ')}</p>
                </div>
            </div>
            <div className="vacancy-button-wrapper">
                <Button link={{pathname: '/vacatures/' + (vacancy?.attributes.available ? vacancy?.attributes.slug : ''), vacancy: vacancy}}>Bekijk</Button>
            </div>
        </div>
    );
}

export default BorderBlockVacancy;
