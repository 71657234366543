import InfoBlock from "../info-block";
import './style.scss';
import {useContext, useEffect, useState} from "react";
import MobileCarousel from "../mobile-carousel";
import { IInfoBlockItemsResponse, IInfoBlockResponse } from "../../../interfaces/strapiTypes/infoBlock";
import parse from 'html-react-parser';
import useSWR from "swr";
import { LocalizationContext } from "../../../App";


const ProblemBlock = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: problemContent } = useSWR<IInfoBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/home-page?populate=ProblemTitle&locale=${locale}`)
    const { data: problemItems } = useSWR<IInfoBlockItemsResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/home-page?populate[ProblemBlocks][populate]=Photo&locale=${locale}`)

    const [width, setWidth] = useState(window.innerWidth);


    const isMobile = width <= 850;

    const handleWindowSize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSize);

        return () => {
            window.removeEventListener('resize', handleWindowSize);
        }
    }, []);

    return (
        <div className="container problem-block-wrapper">
            <div className="problem-block-text-info">
                <h2 className="title">{ problemContent?.data.attributes.ProblemTitle.Title }</h2>
                <p className="sub-title">{ problemContent?.data.attributes.ProblemTitle.Subtitle }</p>
            </div>
            {!isMobile ?
                <div className="problem-block-info">
                    { problemItems?.data.attributes.ProblemBlocks?.map((item, index) => (
                        <InfoBlock key={index} title={item.Title||''} info={item.Subtitle||''}
                                   img={(process.env.REACT_APP_BACKEND_URL || "") + item.Photo?.data.attributes.url}/>
                    ))}
                </div>
                :
                <div className="problem-block-info">
                    <MobileCarousel>
                        { problemItems?.data.attributes.ProblemBlocks?.map((item, index) => (
                            <InfoBlock key={index} title={item.Title||''} info={item.Subtitle||''}
                                       img={(process.env.REACT_APP_BACKEND_URL || "") + item.Photo?.data.attributes.url} />
                        ))}
                    </MobileCarousel>
                </div>
            }

            <div className="problem-block-footer-info-wrapper">
                <div className="problem-block-footer-info">
                    {parse(problemContent?.data.attributes.ProblemTitle.Description || '')}
                </div>
            </div>
        </div>
    );
}

export default ProblemBlock;
