import Button from "../../../button";

import './style.scss'
import parse from 'html-react-parser';
import {IListBlock} from '../../index';

type IInfoImageBlockProps = {
    reverse?: boolean
    buttonType?: 'primary' | 'secondary' | 'transparent',
    listBlock: IListBlock,
    index: number
}


const BigTitleBlock = ({reverse = false, buttonType = 'primary', listBlock, index}: IInfoImageBlockProps) => {
    return (
        <div className={`container big-title-block-item-wrapper ${reverse ? 'reverse' : ''}`}>
            <div className="left-information-block">
                <div className="left-information-block-title">
                    <h2 className="title"><span>{`0${index}.`}</span>{listBlock.Title}</h2>
                </div>
                <div className="left-information-block-text">
                    {parse(listBlock.Content || '')}
                    <div className="left-information-block-small">
                        {parse(listBlock.Smallcontent || '')}
                    </div>
                </div>
                {listBlock.ButtonText ?
                    <div className="left-information-block-button">
                        <Button link={listBlock.ButtonLink}
                                type={buttonType ? buttonType : 'primary'}>
                            {listBlock.ButtonText}
                        </Button>
                    </div>
                    : null
                }
            </div>
            <div className="right-information-block">
                <img
                    src={(process.env.REACT_APP_BACKEND_URL || "") + listBlock.Image.data[0].attributes.url}
                    alt={listBlock.Image.data[0].attributes.alternativeText}/>
            </div>
        </div>
    );
}

export default BigTitleBlock;
