import './style.scss';
import {ICenterBlockResponse} from "../../../interfaces/strapiTypes/infoBlock";
import {Url} from "../../../interfaces/strapiTypes/url";
import useSWR from "swr";
import { useContext } from 'react';
import { LocalizationContext } from '../../../App';

type ITextBlockCenterBlocks = Url & {
    H1?: boolean
}

const TextBlockCenter = ({blockurl, name='', H1 = false}: ITextBlockCenterBlocks) => {
    const { locale } = useContext(LocalizationContext)
    const { data: centerBlockContent } = useSWR<ICenterBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <div className="container text-block-center-wrapper">
            <div className="text-block-center-title-wrapper">
                {H1 ? <h1 className="title">{centerBlockContent?.data.attributes[name].Title}</h1>
                    :<h2 className="title">{centerBlockContent?.data.attributes[name].Title}</h2>}
                <p className="sub-title">{centerBlockContent?.data.attributes[name].Subtitle}</p>
            </div>
            <div className="text-block-center-paragraph-wrapper">
                <span className="paragraph-title sub-title">{centerBlockContent?.data.attributes[name].ParagraphTitle}</span>
                {centerBlockContent?.data.attributes[name].Description}
            </div>
        </div>
    );
}

export default TextBlockCenter;
