import TextBlockCenter from "../../shared/text-block-center";
import './style.scss';
import BorderBlock from "../../shared/border-block";
import { useContext, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { IVacancy, IVacancyResponse } from "../../../interfaces/strapiTypes/vacancy";
import BorderBlockVacancy from "../../shared/border-block/components/border-block-vacancy";
import { LocalizationContext } from "../../../App";

const Vacancies = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: vacanciesData } = useSWR<IVacancyResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/vacancies?populate=deep&locale=${locale}`, { onSuccess:
        (data, key, config) => {
            setVacancies(data?.data)
        }
    })

    const filter = useRef('All')
    const [vacancies, setVacancies] = useState<IVacancy[]>(vacanciesData?.data || [])

    const handleFilter = (filterChange: string) => {
        filter.current = filterChange
        let templist:IVacancy[] = []
        vacanciesData?.data.forEach((vacancy) => {
            if (vacancy.attributes.tag === filterChange || filterChange === 'All' || vacancy.attributes.tag === 'Fulltime_Parttime') {
                templist.push(vacancy)
            }
        })

        if(templist){
            setVacancies(templist)
        }
    }

    useEffect(() => {
        document.title = "RYSST - Vacatures"
    }, [])

    const checkUnavailableVacancies = () => {
        let hasUnavailableVacancies = false

        vacanciesData?.data.forEach((vacancy) => { if (!vacancy.attributes.available) {hasUnavailableVacancies = true} })

        return hasUnavailableVacancies
    }

    return (
        <div className="vacancies-wrapper">
            <TextBlockCenter H1={true} blockurl='vacancy-page?populate=Title' name="Title" />
            <form className="vacancy-filter-form">
                <span className="vacancy-filter" onClick={() => handleFilter('All')} >
                    <span className={`vacancy-filter-checkbox ${filter.current === 'All' ? 'selected' : ''}`} />
                    Alle vacatures
                </span>
                <span className="vacancy-filter" onClick={() => handleFilter('Parttime')} >
                    <span className={`vacancy-filter-checkbox ${filter.current === 'Parttime' ? 'selected' : ''}`} />
                    Parttime
                </span>
                <span className="vacancy-filter" onClick={() => handleFilter('Fulltime')} >
                    <span className={`vacancy-filter-checkbox ${filter.current === 'Fulltime' ? 'selected' : ''}`} />
                    Fulltime
                </span>
            </form>
            <div className="vacancies-container container">
                { vacancies.map((vacancy, index) => {
                    return (
                        vacancy.attributes.available && <BorderBlock key={index}>
                            <BorderBlockVacancy vacancy={vacancy} />
                        </BorderBlock>
                    )
                })}
            </div>
            {checkUnavailableVacancies() && 
            <div className="container">
                <TextBlockCenter blockurl='vacancy-page?populate=TitleUnavailableVacancies' name="TitleUnavailableVacancies" />
                <div className="vacancies-container container">
                    {vacanciesData?.data.map((vacancy, index) => {
                        return (
                            !vacancy.attributes.available && 
                            <div className="vacancy-disabled">
                                <BorderBlock key={index}>
                                    <BorderBlockVacancy vacancy={vacancy} />
                                </BorderBlock>
                            </div>
                        )
                    })}
                </div>
            </div>
            }
        </div>
    );
}

export default Vacancies;
