import './style.scss';
import React, {Children} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

type IMobileCarouselProps = {
    children: React.ReactNode,
}


const MobileCarousel: React.FC<IMobileCarouselProps> = ({children}) => {
    let childrenArray = Children.toArray(children);

    return (
        <div className='container'>
            <div className='mobile-carousel-wrapper'>
                <Carousel
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}>
                    {
                        childrenArray.map((child, index) => {
                            return (
                                <>
                                    {child}
                                </>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    );
}

export default MobileCarousel;
