import TextBlock from '../../shared/text-block';
import './style.scss';
import InfoImageBlock from '../../shared/info-image-block';
import ColumnBlock from '../../shared/column-block';
import ColumnInfo from '../../shared/column-block/column-info';
import BorderBlock from '../../shared/border-block';
import BorderBlockQuote from '../../shared/border-block/components/border-block-quote';
import HighlightBlock from '../../shared/highlight-block';
import { IStudentQuotesResponse } from '../../../interfaces/strapiTypes/quotes';
import useSWR from 'swr';
import { useContext, useEffect } from 'react';
import { LocalizationContext } from '../../../App';

const Student = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: studentQuotes } = useSWR<IStudentQuotesResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/student-page?populate[Quotes][populate][quotes][populate]=Photo&locale=${locale}`)

    useEffect(() => {
        document.title = "RYSST - Voor studenten"
    }, [])

    return (
        <div className="student-wrapper">
            <TextBlock H1={true} blockurl='student-page?populate[HeaderImage][populate][Info][populate]=Media' name='HeaderImage'
            />
            <InfoImageBlock reverse={true} buttonType="transparent"
                            blockurl="student-page?populate[SecondInfoBlock][populate][Info][populate]=Media"
                            name="SecondInfoBlock"/>
            <div className="container quote-wrapper dark-background">
                <h2 className="title quote-title">Waarom werken bij RYSST?</h2>
                <ColumnBlock title="Waarom werken bij RYSST?">
                    <ColumnInfo blockurl='student-page?populate[Column1][populate]=ColumnTitle' name='Column1' buttonType='transparent'/>
                    <ColumnInfo blockurl='student-page?populate=Column2' name='Column2'/>
                    <ColumnInfo blockurl='student-page?populate[Column3][populate]=ColumnTitle' name='Column3'/>
                </ColumnBlock>
            </div>
            <div className="container">
                <h2 className="title question-title">{studentQuotes?.data.attributes.Quotes.Title}</h2>
                <ColumnBlock title="Vragen aan onze studenten">
                    {studentQuotes?.data.attributes.Quotes.quotes.data.map((quote, index) => {
                        if (index > 2) return null;
                        return (
                            <BorderBlock key={index}>
                                <BorderBlockQuote quote={quote.attributes} />
                            </BorderBlock>
                        );
                    })}
                </ColumnBlock>
                <HighlightBlock url="student-page?populate[HighlightBlock][populate]=Media"/>
            </div>
        </div>

    );
}

export default Student;
