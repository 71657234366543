import './style.scss';

import Carousel from '../../../shared/carousel';
import Button from '../../../shared/button';
import {Url} from '../../../../interfaces/strapiTypes/url';
import Companies from '../../../shared/companies';
import useSWR from 'swr';
import {IApiResponse} from '../../../../interfaces/strapiTypes/apiResponse';
import {useContext} from 'react';
import {LocalizationContext} from '../../../../App';

function CallToAction({blockurl}: Url) {
    const {locale} = useContext(LocalizationContext)
    const {data: callToActionData} = useSWR<IApiResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <section className="container call-to-action-wrapper">
            <div className="call-to-action-info-wrapper">
                <div className="info">
                    <h1><Carousel title={callToActionData?.data?.attributes.WelcomeBlock.Title}
                                  data={callToActionData?.data?.attributes.WelcomeBlock.CarouselText.words}/></h1>
                    <p className="sub-title">{callToActionData?.data?.attributes.WelcomeBlock.Subtitle}</p>
                    <Button link={callToActionData?.data?.attributes.WelcomeBlock.ButtonLink}
                            type="secondary">{callToActionData?.data?.attributes.WelcomeBlock.ButtonText}</Button>
                </div>
                <Companies/>
            </div>
            <div className="image-wrapper">
                <video className="image"
                       src={(process.env.REACT_APP_BACKEND_URL || "") + callToActionData?.data?.attributes.WelcomeBlock.Media?.data.attributes.url}
                       about={callToActionData?.data?.attributes.WelcomeBlock.Media?.data.attributes.alternativeText}
                       autoPlay muted loop />
            </div>
        </section>
    );
}

export default CallToAction;
