import "./style.scss";

import CallToAction from './call-to-action';
import InfoImageBlock from "../../shared/info-image-block";
import ReviewBlock from "../../shared/review-block";
import HighlightBlock from "../../shared/highlight-block";
import ProblemBlock from "../../shared/problem-block";
import WorkWayBlock from "./work-way-block";
import { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../App";

const Home = () => {
    const { locale } = useContext(LocalizationContext)

    useEffect(() => {
        document.title = "RYSST - Rent Your Student Scrum Team"
    }, [])

    return (
        <div className="home">
            <CallToAction blockurl={`home-page?populate[WelcomeBlock][populate]=Media&locale=${locale}`}/>
            <ProblemBlock />
            <hr className="seperator" />
            <InfoImageBlock blockurl="home-page?populate[SolutionBlock][populate][Info][populate]=Media" name="SolutionBlock"/>
            <WorkWayBlock />
            <ReviewBlock blockurl="home-page?populate[QuoteBlock][populate][Info][populate]=Media" quoteUrl='home-page?populate[quotes][populate]=Photo'/>
            <hr className="seperator" />
            <HighlightBlock url="home-page?populate[HighlightBlock][populate]=Media" />
        </div>
    );
}

export default Home;
