import Button from "../button";

import './style.scss'
import {useContext, useEffect, useState} from "react";
import {IInfoBlockContentResponse} from "../../../interfaces/strapiTypes/infoBlock";
import parse from 'html-react-parser';
import { Url } from "../../../interfaces/strapiTypes/url";
import useSWR from "swr";
import { LocalizationContext } from "../../../App";

type IInfoImageBlockProps = Url & {
    reverse?: boolean
    buttonType?: 'primary' | 'secondary' | 'transparent'
}

const InfoImageBlock = ({reverse = false, buttonType = 'primary', blockurl, name=''}: IInfoImageBlockProps) => {
    const { locale } = useContext(LocalizationContext)
    const { data: solutionContent } = useSWR<IInfoBlockContentResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 850;

    const handleWindowSize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSize);

        return () => {
            window.removeEventListener('resize', handleWindowSize);
        }
    }, []);

    return (
        <div className={`container solution-block-wrapper ${reverse ? 'reverse' : ''}`}>
            <div className="left-information-block">
                <div className="left-information-block-title">
                    <h2 className="title">{solutionContent?.data.attributes[name].Info.Title}</h2>
                    <p className="sub-title">
                        {solutionContent?.data.attributes[name].Info.Subtitle}
                    </p>
                </div>
                <div className="left-information-block-button">
                    <Button link={solutionContent?.data.attributes[name].Info.ButtonLink} type={buttonType ? buttonType : 'primary'}>
                        {solutionContent?.data.attributes[name].Info.ButtonText}
                    </Button>
                </div>
                {isMobile ?
                    <div className="right-information-block">
                        {solutionContent !== undefined ?
                            <img src={(process.env.REACT_APP_BACKEND_URL || "") + solutionContent?.data?.attributes[name]?.Info?.Media?.data?.attributes?.url}
                                 alt={solutionContent?.data.attributes[name].Info.Media?.data.attributes.alternativeText}/>
                            : null
                        }
                    </div>

                    : null}
                <div className="left-information-block-text">
                    {parse(solutionContent?.data.attributes[name].Content || '')}
                </div>
            </div>
            {!isMobile ?
                <div className="right-information-block">
                    {solutionContent !== undefined ?
                        <img src={(process.env.REACT_APP_BACKEND_URL || "") + solutionContent?.data?.attributes[name]?.Info?.Media?.data?.attributes?.url}
                             alt={solutionContent?.data.attributes[name].Info.Media?.data.attributes.alternativeText}/>
                        : null
                    }

                </div>
                : null}
        </div>
    );
}

export default InfoImageBlock;
