import React, {useContext, useEffect, useState} from 'react';
import './style.scss';
import {IQuotes, IQuotesResponse} from '../../../../../interfaces/strapiTypes/quotes';
import useSWR from 'swr';
import { LocalizationContext } from '../../../../../App';

type IBorderBlockQuoteProps = {
    quote?: IQuotes,
    blockurl?: string
}

const BorderBlockQuote = ({quote, blockurl}: IBorderBlockQuoteProps ) => {
    const { locale } = useContext(LocalizationContext)
    const { data: quoteFromURL } = useSWR<IQuotesResponse>(blockurl ? `${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}` : null, { onSuccess:
        (data, key, config) => {
            setQuoteProps(data?.data?.attributes?.quote?.data?.attributes);
        }
    })

    const [QuoteProps, setQuoteProps] = useState<IQuotes | undefined>(quoteFromURL?.data?.attributes?.quote?.data?.attributes);

    useEffect(() => {
        if (!blockurl) {
            setQuoteProps(quote);
        }
    },[QuoteProps, blockurl, quote])

    return (
        <div className="border-block-quote-wrapper">
            <div className="border-block-quote-details">
                <div className="border-block-quote-image-wrapper">
                    <img src={(process.env.REACT_APP_BACKEND_URL || "") + QuoteProps?.Photo?.data?.attributes?.url} alt={QuoteProps?.Name} />
                </div>
                <div className="border-block-quote-name-wrapper">
                    <p className="border-block-title title-name">{QuoteProps?.Name}</p>
                    <span className="border-block-sub-title job-title">{QuoteProps?.Description}</span>
                </div>
            </div>
            <div className="border-block-text">
                {QuoteProps?.Quote}
            </div>
        </div>
    );
}

export default BorderBlockQuote;
