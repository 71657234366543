import './style.scss';
import Button from "../button";
import { IInfoBlockResponse } from '../../../interfaces/strapiTypes/infoBlock';
import useSWR from 'swr';
import { useContext } from 'react';
import { LocalizationContext } from '../../../App';

type IHighlightProps =  {
    url: string,
    blockName?: string
}

const HighlightBlock = ({url, blockName}: IHighlightProps) => {
    const { locale } = useContext(LocalizationContext)
    const { data: highlightContent } = useSWR<IInfoBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${url}&locale=${locale}`)

    return (
        <div className="container highlight-block-wrapper">
            <div className="highlight-block-title">
                <h2 className="title">{highlightContent?.data.attributes[blockName||'HighlightBlock'].Title}</h2>
                <p className="sub-title">{highlightContent?.data.attributes[blockName||'HighlightBlock'].Subtitle}</p>
            </div>
            <Button link={highlightContent?.data.attributes[blockName||'HighlightBlock'].ButtonLink} type="secondary">
                {highlightContent?.data.attributes[blockName||'HighlightBlock'].ButtonText}
            </Button>
        </div>
    );
}

export default HighlightBlock;
