import {Navigate, useParams} from 'react-router-dom';
import useSWR from 'swr';
import {IBlogPostResponse} from '../../../../interfaces/strapiTypes/blog';
import React, {useContext} from 'react';
import './style.scss';
import parse from 'html-react-parser';
import { LocalizationContext } from '../../../../App';
import Button from "../../../shared/button";

const BlogPost = () => {
    const { slug } = useParams();
    const { locale } = useContext(LocalizationContext)
    const {data: blogPost} = useSWR<IBlogPostResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/blog-posts?filters[Slug][$eq]=${slug}&locale=${locale}&populate=Thumbnail&populate=Banner&populate[0]=localizations&populate=BlogPaper`, { onSuccess:
        (data, key, config) => {
            document.title = `RYSST - Blog | ${data?.data[0]?.attributes.Title}`;
        }
    })

    const {data: blogPostLocalization} = useSWR<IBlogPostResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/blog-posts?fields[0]=Slug&filters[localizations][Slug][$eq]=${slug}&locale=${locale}`);

    if (blogPost?.data?.length === 0 && blogPostLocalization !== undefined) {
        const redirectTarget = blogPostLocalization.data.length === 0 ? '' : `/${blogPostLocalization?.data[0].attributes.Slug}`;
        return (
            <Navigate to={`/blog${redirectTarget}`} replace={true} />
        )
    }

    const forceDownload = (blob: string, filename: string) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

// Current blob size limit is around 500MB for browsers
    const downloadResource = (url: string, filename: string) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
            })
            .catch(e => console.error(e));
    }

    const bannerURL = blogPost?.data[0]?.attributes.Banner?.data?.attributes.url || blogPost?.data[0]?.attributes.Thumbnail.data.attributes.url;

    return (
        <div className="container blog-post-wrapper" >
            <div className="blog-post-banner">
                <h1 className="blog-post-banner-title">{blogPost?.data[0]?.attributes.Title}</h1>
                <img className="blog-post-banner-image" src={(process.env.REACT_APP_BACKEND_URL || '') + bannerURL} alt="Blog post banner"/>
            </div>
            <div className="blog-post ck-content">
                {parse(blogPost?.data[0]?.attributes.Body || '')}
            </div>
            <div className="blog-post-footer">
                {
                    blogPost?.data[0].attributes.ButtonText ?
                        <Button type='primary' clickHandler={() => {downloadResource((process.env.REACT_APP_BACKEND_URL || '') + blogPost?.data[0].attributes.BlogPaper.data[0].attributes.url, blogPost?.data[0].attributes.BlogPaper.data[0].attributes.name)}}>
                            {blogPost?.data[0].attributes.ButtonText}
                        </Button>
                        : ''
                }

            </div>
        </div>
    )
}

export default BlogPost;
