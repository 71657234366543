import './style.scss';

import {useContext, useEffect, useState} from 'react';
import Button from '../shared/button/index';
import RysstLogoWhite from '../../images/1-RYSST_logos/RYSST_White.webp';
import RysstLogoBlack from '../../images/1-RYSST_logos/RYSST_Black.webp';
import MenuIcon from '../../images/menuicon.svg';
import MenuIconBlack from '../../images/menuitem-black.svg';
import MenuItems from './menu-items';
import {Link, useLocation} from 'react-router-dom';
import NightlightIcon from '@mui/icons-material/Nightlight';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {IHeaderFooter} from "../../interfaces/IHeaderFooter";
import { LocalizationContext } from '../../App';
import { KeyboardArrowDown } from '@mui/icons-material';

function Header({theme, locales}: IHeaderFooter) {
    const [checked, setChecked] = useState(false);
    const [headerSticky, setHeaderSticky] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [openLocalization, setOpenLocalization] = useState(false)
    const { locale, setLocale } = useContext(LocalizationContext)
    const {pathname} = useLocation();

    const isMobile = width <= 1280;

    const handleWindowSize = () => {
        setWidth(window.innerWidth);
    }

    const checkForScroll = () => {
        const header = document.getElementById('header');
        if (!header) return;
        if (window.scrollY >= (header.offsetTop + header.offsetHeight) / 2) {
            header.classList.add('sticky');
            setHeaderSticky(true);
        } else {
            header.classList.remove('sticky');
            setHeaderSticky(false);
        }
    }

    const handleBackButton = (e: PopStateEvent) => {
        setChecked(false);
        if (e.state.header) {
            window.history.back();
        }
        if (!isMobile) return;
        if (!checked) return;
        e.preventDefault();
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSize);
        window.addEventListener('scroll', checkForScroll);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('resize', handleWindowSize);
            window.removeEventListener('scroll', checkForScroll);
            window.removeEventListener('popstate', handleBackButton);
        } // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (checked) {
            window.history.pushState({header: true}, 'unused', null);
        }
    }, [checked]);

    const callBack = () => {
        setChecked(false);
    }

    return (
        <div id="header" className='header-containter'>
            <header className={`${checked ? 'mobile-white' : headerSticky ? 'sticky' : ''}`}>
                <div className={`header-wrapper container`}>
                    <div className="header-wrapper-left">
                        <Link to="/" onClick={callBack}>
                            <div className="logo">
                                {!(isMobile && checked) ?
                                    <img
                                        src={!(headerSticky || !theme) || (!theme && headerSticky) ? RysstLogoWhite : RysstLogoBlack}
                                        alt="Rysst logo"/> :
                                    <img src={RysstLogoBlack} alt='Rysst logo'/>
                                }
                            </div>
                        </Link>
                        {/* { isMobile && <div style={{flex: '1 1'}} />} */}
                        <div className="items">
                            <MenuItems callBack={callBack}/>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="menu-button-wrapper">
                            <label htmlFor="collapse-button">
                                {!(isMobile && checked) ?
                                    <img
                                        src={!(headerSticky || !theme) || (!theme && headerSticky) ? MenuIcon : MenuIconBlack}
                                        alt='menuicon'/> :
                                    <img src={MenuIconBlack} alt='menuicon'/>
                                }
                            </label>
                            <input type="checkbox" id="collapse-button" onChange={() => setChecked(!checked)}/>
                        </div>
                        <div className="localization-dropdown" tabIndex={0} onBlur={() => setOpenLocalization(false)}>
                            <p className="localization-dropdown-selected" onClick={() => setOpenLocalization(!openLocalization)}>{locale.toUpperCase()}<KeyboardArrowDown /></p>
                            <div style={{display: openLocalization ? 'flex' : 'none'}} className="localization-dropdown-list">
                                { locales?.data.map((item, index) => {
                                    return (
                                        <span 
                                        key={index} 
                                        title={item.attributes.Enabled ? '' : 'Coming soon'} 
                                        className={item.attributes.Enabled ? '' : 'localization-dropdown-item-disabled'} 
                                        onClick={item.attributes.Enabled ? () => {setLocale(item.attributes.Locale); document.documentElement.lang = item.attributes.Locale; setOpenLocalization(false)} : () => {}}>{item.attributes.Locale.toUpperCase()}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {!theme && <span className="moon-icon" onClick={() => {
                            window.dispatchEvent(new CustomEvent('toggleDarkMode', {detail: {darkMode: true}}))
                        }}>
                            <NightlightIcon sx={{color: !theme && !headerSticky ? "black" : "white"}}/>
                        </span>}
                        {theme && <span className="sun-icon" onClick={() => {
                            window.dispatchEvent(new CustomEvent('toggleDarkMode', {detail: {darkMode: false}}))
                        }}>
                            <WbSunnyIcon sx={{color: theme && !headerSticky ? "white" : "black"}}/>
                        </span>}
                        <Button 
                            link={pathname === '/voor-studenten' ? '/werkwijze' : '/voor-studenten'} 
                            type={headerSticky ? 'transparent-gray' : 'transparent'}
                            isHeader={true}>
                            {pathname === '/voor-studenten' ? 'Voor bedrijven' : 'Voor studenten'}
                        </Button>
                        <Button link="/contact" type="primary" isHeader={true}>Contact</Button>
                    </div>
                </div>
                {isMobile ?
                    checked ?
                        <div className='mobile-menu-items-wrapper'>
                            <div className="mobile-menu-items">
                                <MenuItems callBack={callBack}/>
                                <div className='mobile-menu-footer'>
                                    <Button clickHandler={callBack} type='transparent-gray' isHeader={true}
                                            link={pathname === '/voor-studenten' ? '/werkwijze' : '/voor-studenten'}>
                                        {pathname === '/voor-studenten' ? 'Voor bedrijven' : 'Voor studenten'}
                                    </Button>
                                    <Button link='/contact' type='primary' isHeader={true} clickHandler={callBack}>Contact</Button>
                                    <p>
                                        Bij RYSST zorgen we ervoor dat talentvolle IT-studenten en mooie opdrachtgevers
                                        elkaar vinden.
                                    </p>
                                    <p className='copyright'>Copyright 2022 RYSST</p>
                                </div>
                            </div>
                        </div> : null
                    : null}
            </header>
        </div>
    );
}

export default Header;
