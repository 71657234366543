import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash, key } = useLocation();

  const scrollToHash = () => {
    const elementToScroll = document.getElementById(hash?.replace("#", ""));
    if (!elementToScroll) return;

    window.scrollTo({
      top: elementToScroll.offsetTop - 140
    });
  };

  useEffect(() => {
    if (!hash)  
      window.scrollTo(0, 0);
    else
      scrollToHash()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hash, key]);

  return null;
}

export default ScrollToTop