import './style.scss'
import { useContext } from 'react'
import useSWR from 'swr'
import { LocalizationContext } from '../../../App'
import { IInfoBlockResponse } from '../../../interfaces/strapiTypes/infoBlock'
import Button from '../../shared/button'

const PageNotFound = () => {
  const { locale } = useContext(LocalizationContext)
  const { data: pageContent } = useSWR<IInfoBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/not-found-page?populate=deep&locale=${locale}`)

  return (
    <div className="wrapper-404">
      <img src={(process.env.REACT_APP_BACKEND_URL || "") + pageContent?.data.attributes.Content.Media.data.attributes.url} alt="RYSST koker" />
      <h1 className="title">{pageContent?.data.attributes.Content.Title}</h1>
      <p className="sub-title">{pageContent?.data.attributes.Content.Subtitle}</p>
      <Button type="transparent" link={pageContent?.data.attributes.Content.ButtonLink}>{pageContent?.data.attributes.Content.ButtonText}</Button>
    </div>
  )
}

export default PageNotFound