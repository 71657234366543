import './style.scss';

// @ts-ignore
import HubspotForm from 'react-hubspot-form';
import Button from '../button';
import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { CookieContext } from '../../../App';

type  IFormProps =  {
    formId: string
}

const Form = ({formId}: IFormProps) => {
  const { cookieConsent } = useContext(CookieContext)

  return (
    <>
      { cookieConsent === 'true' ?
        <HubspotForm 
          portalId={26155363}
          formId={formId}
          loading={<CircularProgress />}/>
        :<div className="contact-form-no-cookies">
            <p>Formulier kan niet worden geladen, omdat cookies zijn geweigerd</p>
            <Button clickHandler={() => window.dispatchEvent(new Event('acceptCookies'))}>Cookies accepteren</Button>
        </div>}
    </>
  );
};

export default Form;
