import React, { createContext, useEffect, useState } from 'react';
import './App.scss';
import Header from './components/header/index';
import Home from './components/pages/home/index';
import About from './components/pages/about/index';
import Footer from './components/footer/index';
import OurTeams from "./components/pages/ourTeams";
import Student from './components/pages/student';
import Vacancies from './components/pages/vacancies';
import WorkWayPage from "./components/pages/workway";
import Vacancy from './components/pages/vacancies/vacancy';
import Contact from './components/pages/contact';
import ScrollToTop from './components/scroll';
import GA4React from "ga-4-react";
import {hotjar} from 'react-hotjar';
import CookieConsent, {Cookies, getCookieConsentValue} from "react-cookie-consent";

import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import BlogPage from './components/pages/blog';
import BlogPost from './components/pages/blog/blog-post';
import useSWR from 'swr';
import { ILocalesResponse } from './interfaces/strapiTypes/locale';
import PageNotFound from './components/pages/404';
import Whitepaper from "./components/pages/whitepaper";

const GA_TOKEN = window.location.href.startsWith('https://test.rysst.internal.works') || process.env.NODE_ENV === 'development' ? 'G-Q0M6ZWEH1Q' : 'G-E8EGX74BE8'
const HOTJAR_SITE_TOKEN = window.location.href.startsWith('https://test.rysst.internal.works') || process.env.NODE_ENV === 'development' ? 3133090 : 3133080
const HOTJAR_SITE_TOKEN_VERSION = window.location.href.startsWith('https://test.rysst.internal.works')  || process.env.NODE_ENV === 'development' ? 6 : 6
const HUBSPOT_SCRIPT = window.location.href.startsWith('https://test.rysst.internal.works')  || process.env.NODE_ENV === 'development' ? '' : '//js-eu1.hs-scripts.com/26155363.js'

const ga4react = new GA4React(GA_TOKEN);

const initHubspot = () => {
    const script = document.createElement('script');
    script.src = HUBSPOT_SCRIPT;
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    script.type = 'text/javascript'

    document.body.appendChild(script);
}

export const LocalizationContext = createContext({
    locale: "nl",
    setLocale: (locale: string) => {},
  });

export const CookieContext = createContext({
    cookieConsent: getCookieConsentValue(),
    setCookieConsent: (cookieConsent: string | undefined) => {},
  });

function App() {

    const [darkMode, setDarkMode] = useState<boolean>(true)
    const { data: locales } = useSWR<ILocalesResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/languages?populate=deep`, { onSuccess:
        (data, key, config) => {
            setInitialLanguage(data)
        }
    })

    const [locale, setLocale] = useState("nl");
    const valueLocale = { locale, setLocale }
    const [cookieConsent, setCookieConsent] = useState<string|undefined>(getCookieConsentValue())
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const valueCookie = {cookieConsent, setCookieConsent}

    const handleAcceptCookie = () => {
        if(!isInitialized){
            setIsInitialized(true);
            ga4react.initialize().then((ga4) => {
                ga4.pageview('path')
                ga4.gtag('event', 'pageview', 'path') // or your custom gtag event
            })
        }

        hotjar.initialize(
            Number(HOTJAR_SITE_TOKEN) || 0,
            Number(HOTJAR_SITE_TOKEN_VERSION) || 0);

        initHubspot()

        setCookieConsent('true')
    }

    const setInitialLanguage = (languages: ILocalesResponse) => {
        if (languages.data.length <= 1) return

        let preferedLocale = ''
        const availableLocales = languages?.data?.map((item) => {
            return item.attributes.Enabled ? item.attributes.Locale : null
        })

        if (navigator.languages && navigator.languages.length) {
            navigator.languages.forEach((locale) => {
                if (preferedLocale === '' && availableLocales.includes(locale.substring(0, 2))) {
                    preferedLocale = locale.substring(0, 2)
                }
            })
        } else if (navigator.language) {
            preferedLocale = availableLocales.includes(navigator.language.substring(0, 2)) ? navigator.language.substring(0, 2) : 'nl'
        }

        document.documentElement.lang = preferedLocale === '' ? 'nl' : preferedLocale
        setLocale(preferedLocale === '' ? 'nl' : preferedLocale)
    }

    useEffect(() => {
        window.addEventListener('toggleDarkMode', (e: any) => {
            document.documentElement.setAttribute('data-theme', e.detail.darkMode ? 'dark' : 'light')
            setDarkMode(e.detail.darkMode)
        })

        window.addEventListener('acceptCookies', (e: any) => {
            Cookies.set('CookieConsent', 'true')
            handleAcceptCookie()
        })

        if (cookieConsent === 'true') {
            handleAcceptCookie()
        }

        return () => {
            window.removeEventListener('toggleDarkMode', (e: any) => {
                document.documentElement.setAttribute('data-theme', e.detail.darkMode ? 'dark' : 'light')
            })
        }
    });

    return (
        <Router>
            <CookieContext.Provider value={valueCookie}>
                <LocalizationContext.Provider value={valueLocale}>
                    <ScrollToTop/>
                    {cookieConsent === undefined && <CookieConsent enableDeclineButton onAccept={handleAcceptCookie}
                                buttonText="Akkoord"
                                declineButtonText="Geen cookies"
                                buttonWrapperClasses="cookie-button-wrapper"
                                buttonClasses="cookie-button-accept"
                                declineButtonClasses="cookie-button-decline"
                                containerClasses="cookie-container"
                                contentClasses="cookie-content-wrapper"
                    >
                        Deze website maakt gebruik van cookies. <a aria-label="learn more about cookies"
                                                                href="https://www.consuwijzer.nl/veilig-online/cookies"
                                                                rel="noopener noreferrer nofollow" target="_blank">Lees
                        meer</a>
                    </CookieConsent>}
                    <Header theme={darkMode} locales={locales}/>
                    <div className="wrapper wrapper-main">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/overrysst" element={<About />}/>
                            <Route path="/teams" element={<OurTeams/>}/>
                            <Route path="/voor-studenten" element={ <Student /> } />
                            <Route path="/werkwijze" element={<WorkWayPage />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/vacatures" element={<Vacancies />} />
                            <Route path="/vacatures/:slug" element={<Vacancy />} />
                            <Route path="/blog" element={<BlogPage />} />
                            <Route path="/blog/:slug" element={<BlogPost />} />
                            <Route path="/whitepaper" element={<Whitepaper />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </div>
                    <Footer theme={darkMode} />
                </LocalizationContext.Provider>
            </CookieContext.Provider>
        </Router>
    );
}

export default App;
