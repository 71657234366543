import './style.scss'
import {Url} from "../../../interfaces/strapiTypes/url";
import {IApiResponse} from "../../../interfaces/strapiTypes/apiResponse";
import useSWR from 'swr';
import { LocalizationContext } from '../../../App';
import { useContext } from 'react';

const HeaderImage = ({blockurl}: Url) => {
    const { locale } = useContext(LocalizationContext)
    const { data: headerImageContent } = useSWR<IApiResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <div className="container header-image-wrapper">
            <img src={(process.env.REACT_APP_BACKEND_URL || "") + headerImageContent?.data.attributes.headerImage.data.attributes.url} alt="top" />
        </div>
    );
}

export default HeaderImage;
