import './style.scss';
import React from 'react';

type IBorderBlockProps = {
    children: React.ReactNode
}

const BorderBlock = ({children}: IBorderBlockProps) => {
    return (
        <div className='border-block'>
            <div className='border-block-wrapper'>
                {children}
            </div>
        </div>
    );
}

export default BorderBlock;
