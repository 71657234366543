import InfoBlock from "../../../shared/info-block";
import Button from '../../../shared/button/index';
import './style.scss'

import MobileCarousel from "../../../shared/mobile-carousel";
import React, {useContext, useEffect, useState} from "react";
import {IApiResponse} from "../../../../interfaces/strapiTypes/apiResponse";
import {IInfoBlock, IInfoBlockResponse} from "../../../../interfaces/strapiTypes/infoBlock";
import useSWR from "swr";
import { LocalizationContext } from "../../../../App";

export type IWorkWayItems = IApiResponse & {
    data: {
        attributes: {
            WorkwayInfoBlocks: IInfoBlock[]
        }
    }
}

const WorkWayBlock = () => {
    const { locale } = useContext(LocalizationContext)
    const { data: workwayContent } = useSWR<IInfoBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/home-page?populate=WorkwayBlock&locale=${locale}`)
    const { data: workwayItems } = useSWR<IWorkWayItems>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/home-page?populate[WorkwayInfoBlocks][populate]=Photo&locale=${locale}`)

    const [width, setWidth] = useState(window.innerWidth);

    const isMobile = width <= 850;

    const handleWindowSize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSize);

        return () => {
            window.removeEventListener('resize', handleWindowSize);
        }
    }, []);
    return (
        <div className="container work-way-block-wrapper dark-background">
            <div className="work-way-block-header">
                <h2 className="title">{workwayContent?.data.attributes.WorkwayBlock.Title}</h2>
                <p className="sub-title">{workwayContent?.data.attributes.WorkwayBlock.Subtitle}</p>
            </div>
            {!isMobile ?
                <div className="work-way-block-content">
                    {workwayItems?.data.attributes.WorkwayInfoBlocks.map((item, index) => {
                        return ([
                            <InfoBlock key={index} title={item.Title || ''} info={item.Subtitle || ''}
                                       img={(process.env.REACT_APP_BACKEND_URL || "") + item.Photo?.data.attributes.url}/>,

                            index < workwayItems?.data.attributes.WorkwayInfoBlocks.length - 1 && <div key={index + workwayItems?.data.attributes.WorkwayInfoBlocks.length} className="seperator vertical"></div>
                        ])
                    })}
                </div>
                :
                <div className="work-way-block-content">
                    <MobileCarousel>
                        {workwayItems?.data.attributes.WorkwayInfoBlocks.map((item, index) => (
                            <InfoBlock key={index} title={item.Title || ''} info={item.Subtitle || ''}
                                       img={(process.env.REACT_APP_BACKEND_URL || "") + item.Photo?.data.attributes.url}/>


                        ))}
                    </MobileCarousel>
                </div>
            }
            <div className="work-way-block-footer">
                <Button link={workwayContent?.data.attributes.WorkwayBlock.ButtonLink} type="secondary">
                    {workwayContent?.data.attributes.WorkwayBlock.ButtonText}
                </Button>
            </div>
        </div>
    );
}

export default WorkWayBlock;
