import useSWR from 'swr';
import BlogPostTeaser from '../blog-post-teaser';
import {IBlogPostResponse} from '../../../../interfaces/strapiTypes/blog';
import {useContext} from 'react';
import {LocalizationContext} from '../../../../App';

type IBlogListProps = {
    blockurl: string,
    selectedTags: string[]
}

const BlogList = ({blockurl, selectedTags}: IBlogListProps) => {
    const { locale } = useContext(LocalizationContext)
    const {data: blogLists} = useSWR<IBlogPostResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}&sort=id%3Adesc`);

    return (
        <div className="blog-list">
            {blogLists?.data?.filter(blog => selectedTags.length > 0 ? selectedTags.includes(blog.attributes.tag?.data?.attributes.Name) : true).map((blog, index) => {
                return <BlogPostTeaser key={blog.id} reverse={index % 2 === 1} wideThumbnail={blog.attributes.WideThumbnail} tag={blog.attributes.tag?.data?.attributes?.Name || ''} title={blog.attributes.Title} slug={blog.attributes.Slug} imgUrl={blog.attributes.Thumbnail?.data?.attributes.url}/>
            })}
        </div>
    )
}

export default BlogList;
