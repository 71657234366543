import {ITeamMember} from '../../../interfaces/strapiTypes/teamMember';
import './style.scss';

type IMemberImageProps = {
    member: ITeamMember
}

const MemberImage = ({member}: IMemberImageProps) => {
    return (
        <div className="member-image-wrapper">
            <img draggable="false" src={(process.env.REACT_APP_BACKEND_URL || '') + member.attributes.Photo.data.attributes.url} alt={member.attributes.Name}/>
            <span className="side-text">{member.attributes.Name}</span>
        </div>
    )
}

export default MemberImage;
