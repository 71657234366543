import React, {useContext, useState} from 'react';
import './style.scss';
import {Url} from '../../../interfaces/strapiTypes/url';
import {IApiResponse} from '../../../interfaces/strapiTypes/apiResponse';
import BorderBlock from '../border-block';
import BorderBlockQuote from '../border-block/components/border-block-quote';
import {IQuotes} from '../../../interfaces/strapiTypes/quotes';
import useSWR from 'swr';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import { LocalizationContext } from '../../../App';

type ISlideProps = {
    id: number,
    checked: boolean,
    prechecked: boolean,
    attributes: IQuotes
}

const QUOTE_HEIGHT = 325 + 17


const HorizontalCarousel = ({blockurl}: Url) => {
    const { locale } = useContext(LocalizationContext)
    const { data: quoteData } = useSWR<IApiResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`, { onSuccess:
        (data, key, config) => {
            const tempList: ISlideProps[] = [];

            let responseSlide: ISlideProps[] = data?.data.attributes.quotes.data

            responseSlide.forEach((child, index) => {
                tempList.push({id: child.id, checked: index === selectedIndex, prechecked: index === selectedIndex + 1, attributes: child.attributes})
            });

            setSlides(tempList);
        }
    })

    const [slides, setSlides] = useState<ISlideProps[]>(quoteData?.data.attributes.quotes.data||[])
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [slidePosition, setSlidePosition] = useState<number>(0)

    const onNext = () => {
        let index = selectedIndex
        if (selectedIndex >= slides.length - 1){
            index = 0
            setSelectedIndex(index);

        } else {
            index = selectedIndex + 1
            setSelectedIndex(index);
        }

        changeSlides(index)
        changeSidePosition('down')
    }

    const changeSlides = (selectedIndex: number) => {
        const temp: ISlideProps[] = [];
        slides.forEach((slide, index) => {
            slide.checked = index === selectedIndex
            let preIndex = selectedIndex + 1 <= slides.length - 1 ? selectedIndex + 1 : 0
            slide.prechecked = index === preIndex
            temp.push(slide)
        })
        setSlides(temp);
    }

    const changeSidePosition = (direction: 'up'|'down') => {
        let position = 0
        if (direction === 'up')
            position = slidePosition === 0 ? (slides.length - 1) * -QUOTE_HEIGHT : slidePosition + QUOTE_HEIGHT
        else
            position = slidePosition - QUOTE_HEIGHT === (slides.length) * -QUOTE_HEIGHT ? 0 : slidePosition - QUOTE_HEIGHT
        setSlidePosition(position)
    }

    return (
        <div className="quote-carousel">
            <div className="quote-carousel-wrapper">
                <div className="quote-carousel-items" style={{top: slidePosition}}>
                    {
                        slides.map((child, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`slide carousel-slide-${index} 
                                    ${slides[index]?.prechecked ? 'pre-selected' : ''} 
                                    ${slides[index]?.checked ? 'selected' : ''}`}>
                                    <BorderBlock>
                                        <BorderBlockQuote quote={child.attributes} />
                                    </BorderBlock>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="quote-carousel-navigation down" onClick={onNext}>
                    {slides.length === 1 ? null : (selectedIndex === slides.length - 1 ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                </div>
            </div>
        </div>
    );
}

export default HorizontalCarousel;
