import React, {MouseEventHandler} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import './style.scss'
import { Link } from 'react-router-dom';

type ILink = {
  pathname: string,
  [key: string]: any
}

type IButtonProps = {
  type?: 'primary'|'secondary'|'transparent' | 'transparent-gray'
  clickHandler?: MouseEventHandler
  children?: React.ReactNode
  link?: string | ILink
  isHeader?: boolean
}

const Button = ({clickHandler, children, link, type = 'primary', isHeader = false}: IButtonProps) => {
  const ButtonComponent = () => {
    return (
      <button onClick={clickHandler} className={`${type} ${isHeader ? 'header' : ''}`}>
        <span className="button-text">{children}</span>
        <span className="icon-wrapper">
          <ArrowForwardIcon fontSize='inherit' className="icon"/>
        </span>
      </button>
    )
  }

  return (
      <>
        {link ? 
          <Link to={link || '/'}>
            {ButtonComponent()}
          </Link> 
          : ButtonComponent()}
      </>
  );
}

export default Button;
