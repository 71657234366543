import {useContext, useEffect} from "react";
import useSWR from "swr";
import {LocalizationContext} from "../../../App";
import {Url} from "../../../interfaces/strapiTypes/url";
import BigTitleBlock from "./components/big-title-block";
import {IApiResponse} from "../../../interfaces/strapiTypes/apiResponse";
import './style.scss';

export type IListBlock = {
    Title: string,
    Content: string,
    Smallcontent?: string,
    ButtonText?: string,
    ButtonLink?: string,
    Image: any,
}

type IListBlockResponse = IApiResponse & {
    data: {
        attributes: {
            Listblock: IListBlock[]
        }
    }
}

const BigTitleBlockWrapper = ({blockurl, name=''}: Url) => {
    const {locale} = useContext(LocalizationContext)
    const {data: content} = useSWR<IListBlockResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    useEffect(() => {
        console.log(content);
    }, [content])

    return (
        <div className="big-title-block-wrapper">
            {content?.data.attributes.Listblock.map((data, index) => {
                return <BigTitleBlock index={index + 1} listBlock={data} reverse={index % 2 === 0 ? false : true}/>
            })}
        </div>
    );
}

export default BigTitleBlockWrapper;
