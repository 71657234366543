
import './style.scss'

type IInfoBlockProps = {
    title: string,
    info: string,
    img?: string
}

const InfoBlock = ({title, info, img}: IInfoBlockProps) => {
    return(
        <div className="block">
            <div className="image-wrapper">
                {img ?
                    <img src={img} alt={title} />
                    : null
                }
            </div>
            <div className="block-text">
                <h3>{title}</h3>
                <p className="info" >{info}</p>
            </div>
        </div>
    );
}

export default InfoBlock;
