import './style.scss'
import parse from 'html-react-parser';

import QuoteImage from '../../../images/quote-shape.svg';
import Button from "../button";
import QuoteCarousel from "../quote-carousel";

import {Url} from "../../../interfaces/strapiTypes/url";
import { IInfoBlockContentResponse } from "../../../interfaces/strapiTypes/infoBlock";
import useSWR from 'swr';
import { useContext } from 'react';
import { LocalizationContext } from '../../../App';

type IReviewBlockProps = Url & {
    quoteUrl: string
}

const ReviewBlock = ({blockurl, quoteUrl}: IReviewBlockProps) => {
    const { locale } = useContext(LocalizationContext)
    const { data: reviewContent } = useSWR<IInfoBlockContentResponse>(`${process.env.REACT_APP_BACKEND_URL || ''}/api/${blockurl}&locale=${locale}`)

    return (
        <div id="reviews" className="container review-block-wrapper">
            <div className="review-block-left">
                <div className="review-block-left-title-img">
                    <img src={QuoteImage} alt="quote"/>
                </div>
                <div className="review-block-title">
                    <h2 className="title">{reviewContent?.data.attributes.QuoteBlock.Info.Title}</h2>
                    { reviewContent?.data.attributes.QuoteBlock.Info.Subtitle && 
                    <p className="sub-title">{reviewContent?.data.attributes.QuoteBlock.Info.Subtitle}</p>}
                </div>
                <div className="review-block-content">
                    {parse(reviewContent?.data.attributes.QuoteBlock.Content||'')}
                </div>
                <div className="review-block-footer">
                    { reviewContent?.data.attributes.QuoteBlock.Info.ButtonText && 
                        <Button link={reviewContent?.data.attributes.QuoteBlock.Info.ButtonLink}>
                            {reviewContent?.data.attributes.QuoteBlock.Info.ButtonText}
                        </Button>
                    }
                </div>
            </div>
            <div className="review-block-right">
                <QuoteCarousel blockurl={quoteUrl} />
            </div>
        </div>
    );
}

export default ReviewBlock;
