import HeaderImage from "../../shared/header-image";
import TextBlockCenter from "../../shared/text-block-center";
import './style.scss';
import Divider from "../../shared/divider";
import TextBlock from "../../shared/text-block";
import ColumnBlock from "../../shared/column-block";
import ColumnInfo from "../../shared/column-block/column-info";
import BorderBlock from "../../shared/border-block";
import BorderBlockQuote from "../../shared/border-block/components/border-block-quote";
import HighlightBlock from "../../shared/highlight-block";
import TeamCarousel from "../../shared/team-carousel";
import { useEffect } from "react";

const About = () => {

    useEffect(() => {
        document.title = "RYSST - Over RYSST"
    }, [])

    return (
        <div className="about-wrapper">
            <TextBlockCenter H1={true} blockurl='about-page?populate=Title' name="Title" />
            <HeaderImage blockurl='about-page?populate=headerImage' />
            <TextBlock blockurl='about-page?populate[WhatRYSSTDoes][populate][Info][populate]=Media' buttonType='secondary' name='WhatRYSSTDoes'/>
            <Divider type="horizontal" color="gray" />
            <ColumnBlock>
                <ColumnInfo blockurl='about-page?populate=Column1' name='Column1' />
                <ColumnInfo blockurl='about-page?populate=Column2' name='Column2'/>
                <BorderBlock>
                    <BorderBlockQuote blockurl='about-page?populate[quote][populate]=Photo' />
                </BorderBlock>
            </ColumnBlock>
            <Divider type="horizontal" color="gray" />
            <h2 className='title student-title'>Ontmoet onze studenten</h2>
            <TeamCarousel type="wide" blockurl="about-page?populate[Employees][populate][team_members][populate]=Photo"/>
            <HighlightBlock url="about-page?populate=HighLightBlock" blockName="HighLightBlock" />
        </div>
    );
}

export default About;
