import './style.scss';

type  IDividerProps =  {
    type: 'horizontal'|'vertical',
    color: 'gray'|'white'|'blue',
    className?: string,
}

const Divider = ({type = 'horizontal', color = 'gray', className = ''}: IDividerProps) => {
    return (
        <div className={`container ${className}`}>
            <div className={`${type} ${color}`}></div>
        </div>

    );
};

export default Divider;
